import React, { ReactNode, useEffect, useState } from 'react';
import { create } from 'zustand';
import './backgrounds.css';
import { useLocation } from 'react-router';
interface BackgroundProviderProps {
    children: ReactNode;
}

export const BACKGROUNDS = {
    LastCall: 'last-call',
    Lvls: 'lvls',
    Gradient: 'gradient',
    Classroom: 'classroom',
    Blank: 'blank',
    Bookshelf: 'bookshelf',
    Gathering: 'gathering',
} as const;

export type Background = (typeof BACKGROUNDS)[keyof typeof BACKGROUNDS];

const BackgroundProvider = ({ children }: BackgroundProviderProps) => {
    const { pathname } = useLocation();
    const [background, setBackground] = useState<Background>(BACKGROUNDS.Gradient);
    useEffect(() => {
        if (pathname.endsWith('/uncommon-academy') || pathname.includes('/uncommon-academy/home')) {
            setBackground(BACKGROUNDS.Classroom);
        } else if (pathname.includes('/uncommon-academy')) {
            setBackground(BACKGROUNDS.Bookshelf);
        } else if (pathname.includes('/competitions/last-call')) {
            setBackground(BACKGROUNDS.LastCall);
        } else if (pathname.includes('/competitions/lvls')) {
            setBackground(BACKGROUNDS.Lvls);
        } else if (pathname.includes('/events/the-gathering')) {
            setBackground(BACKGROUNDS.Gathering);
        } else if (pathname.includes('/components-testing')) {
            setBackground(BACKGROUNDS.Blank);
        } else {
            setBackground(BACKGROUNDS.Gradient);
        }
    }, [pathname]);
    return <div className={background}>{children}</div>;
};

// interface BackgroundStore {
//     background: (typeof BACKGROUNDS)[keyof typeof BACKGROUNDS];
//     setBackground: (value: (typeof BACKGROUNDS)[keyof typeof BACKGROUNDS]) => void;
// }

// export const useBackgroundProvider = create<BackgroundStore>((set, get) => ({
//     background: BACKGROUNDS.Gradient,
//     setBackground: (value: (typeof BACKGROUNDS)[keyof typeof BACKGROUNDS]) => set({ background: value }),
// }));

export default BackgroundProvider;
